import React from "react"
import { StaticQuery, graphql } from 'gatsby'

const Footer = () => (
    <StaticQuery
        query={graphql`
            query {
                wordpressAcfOptions {
                    options {
                    content
                    }
                }
            }
        `}
    render={data => (
      <div className="footerbody">
         <div dangerouslySetInnerHTML={{ __html: data.wordpressAcfOptions.options.content }} />
      </div>
    )}
  />
)


export default Footer

import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from 'react'

import Menu from "../components/menu"
import Burger from "../components/burger"


const Header = ({ siteTitle }) => { 
  const [open, setOpen] = useState(false)
  return (
  <header className="grid-header">
    <div className="item a">
      <h1><Link to="/">mesmer<span>rahmen</span></Link></h1>
      <div className="navbuger">
         <Burger open={open} setOpen={setOpen} className="burger"></Burger>
      </div>
    </div>
    <div className="item b">
      <Menu open={open} setOpen={setOpen}></Menu>
    </div>
  </header>
  )
 }

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header


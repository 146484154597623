import { StaticQuery, graphql} from "gatsby"
import React from 'react';
import { bool, func } from 'prop-types'
import AniLink from "gatsby-plugin-transition-link/AniLink"
import styled from 'styled-components'

export const NavMenu = styled.div`

  display: flex;
  flex-direction: column;
  ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      display:  flex;
      justify-content: space-between;
      li {
          list-style: none;
          margin: 0;
          padding: 0;
          align-items: center;
          a {
              color: black;
              list-style: none;
              &:hover {
                color: grey;
                transition: color .5s ease;
              }
              &.active {
                font-weight: 600;
              }
          }
      }
  }


@media (max-width: 768px) {
  .navbuger {
      display: inline;
      float: right;
      margin-top: 0.5rem;
  }

      display: block;
      height: 100%;
      background-color: white;
      transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(-200%)'};
      ul {
          display: block;
          height: auto;
          background-color: white;
          border-bottom: 2px solid;
          li {
           margin: 0.5rem 0;
          }
      }
  }

`

const Menu = ({ open, setOpen }) => (
    <StaticQuery
        query={graphql`
            query {
              wordpressWpApiMenusMenusItems(name: { eq: "main-menu" }) {
                items {
                  title
                  object_slug
                }
              }
            }
        `}
        render={data => (
              <NavMenu open={open} onClick={() => setOpen(!open)}>
                <ul>
                  {data.wordpressWpApiMenusMenusItems.items.map(item => (
                    <li key={item.object_slug} className="nav-item">
                      <AniLink  to={`/${item.object_slug}`} activeClassName="active" duration={0.3}>
                        {item.title}
                      </AniLink >
                    </li>
                  ))}
                </ul>
              </NavMenu>
        )}
    />
)

Menu.propTypes = {
    open: bool.isRequired,
    setOpen: func.isRequired,
    }

export default Menu